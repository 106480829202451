import {graphql, Link, useStaticQuery} from "gatsby"
import React from "react"
import Navbar from "react-bootstrap/Navbar";

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <Navbar bg="white" expand="lg" className={"py-2 shadow-sm fixed-top"}>
      <Link to="/" className={"m-auto py-2"}>
        <img src={data.logo.publicURL} style={{ width: "160px" }} className={"mb-0"} alt={"MioPediatra"} />
      </Link>
    </Navbar>
  )
}

export default Header
