import {graphql, Link, useStaticQuery} from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Container} from "react-bootstrap";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-white.svg" }) {
        publicURL
      },
      allDataJson {
        nodes {
          property_of
          tos_link_text
          tos_link_label
          support_link_text
        }
      }
    }
  `)

  return (
    <Container fluid className={"footer"}>
      <Row className={"align-items-center justify-content-center bg-dark darker text-dark"}>
        <Col className={"text-center"}>
          <div className={"my-4"}>
            <img src={data.logo.publicURL} style={{ width: "80px" }} className={"m-auto"} alt={"MioPediatra"}/>
          </div>
          <p className={"mb-2"}>{data.allDataJson.nodes[0].property_of}</p>
          <p className={"mb-2"}>{data.allDataJson.nodes[0].tos_link_text} <Link to={"/termini-di-servizio"} className={"text-white"}>{data.allDataJson.nodes[0].tos_link_label}</Link></p>
          <p className={"mb-0 pb-4"}>{data.allDataJson.nodes[0].support_link_text} <a href={"mailto:info@miopediatra.com"} className={"text-white"}>info@miopediatra.com</a></p>
        </Col>
      </Row>
    </Container>
  )
}

export default Footer
