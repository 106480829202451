import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      app: file(relativePath: { eq: "ios.svg" }) {
        publicURL
      }
      app_sm: file(relativePath: { eq: "app-sm.svg" }) {
        publicURL
      }
      app_store: file(relativePath: { eq: "apple-store.svg" }) {
        publicURL
      }
      google_play: file(relativePath: { eq: "google-play.svg" }) {
        publicURL
      }
      parent_img: file(relativePath: { eq: "parent-f.svg" }) {
        publicURL
      }
      i_lock: file(relativePath: { eq: "icons/lock.svg" }) {
        publicURL
      }
      i_sec: file(relativePath: { eq: "icons/sec.svg" }) {
        publicURL
      }
      i_cloud: file(relativePath: { eq: "icons/cloud.svg" }) {
        publicURL
      }
      i_badge: file(relativePath: { eq: "icons/badge.svg" }) {
        publicURL
      }
      i_circle: file(relativePath: { eq: "icons/circle.svg" }) {
        publicURL
      }
      allDataJson {
        nodes {
          parents {
            title
            desc
            desc_mobile
            app_free
            section_2 {
             title
             desc
             blocks 
            }
            upsell_title
            upsell_desc
            features {
             title
             desc
            }
            faq {
              title
              qa {
                q
                a
              }
            }
            upcoming_features {
              title
              list
            }
            support
          }
        }
      }
    }
  `)

  function feat_icon(i) {
    return ['i_lock', 'i_sec', 'i_cloud', 'i_badge'][i];
  }

  return (
    <Layout>
      <SEO title="Genitori" />
      <div className={"page parents bg-white overflow-hidden"}>

        <Row className={"bg-white justify-content-center pt-page d-none d-sm-none d-md-flex"}>
          <Col md={{ span: 7, order: 1}} lg={5} xl={5} className={"text"}>
            <h1 className={""} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.title}}/>
            <p dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.desc}} />
            <p dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.app_free}} />
            <div className={"d-flex"}>
              <a href="https://apps.apple.com/app/id1512934824" target={"_blank"} rel="noreferrer"><img style={{ height: "48px" }} src={data.app_store.publicURL} alt={"App Store"}/></a>
              <a href="https://play.google.com/store/apps/details?id=it.miopediatra" target={"_blank"} rel="noreferrer"><img style={{ height: "48px" }} className={"ml-2"} src={data.google_play.publicURL} alt={"Google Play"}/></a>
            </div>
          </Col>
          <Col md={{ span: 4, order: 2}} lg={3} xl={3} className={""}>
            <img src={data.app.publicURL} alt={"App"} style={{ maxHeight: '640px' }}/>
          </Col>
        </Row>

        <Row className={"bg-white pt-5 justify-content-center d-md-none"}>
          <Col xs={{ span: 10, order: 2}} style={{ marginTop: "-14vh" }}>
            <h1 className={""} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.title}}/>
            <p dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.desc}} />
            <p dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.app_free}} />
            <div className={"d-flex"}>
              <a href="https://apps.apple.com/app/id1512934824" target={"_blank"} rel="noreferrer"><img style={{ height: "48px" }} src={data.app_store.publicURL} alt={"App Store"}/></a>
              <a href="https://play.google.com/store/apps/details?id=it.miopediatra" target={"_blank"} rel="noreferrer"><img style={{ height: "48px" }} className={"ml-2"} src={data.google_play.publicURL} alt={"Google Play"}/></a>
            </div>
          </Col>
          <Col xs={{ span: 12, order: 1}} className={"d-md-none"}>
            <img src={data.app_sm.publicURL} alt={"App"} className={"app-mobile pt-5"}/>
          </Col>
        </Row>

        <Row className={"justify-content-center bg-light section-2 d-none d-lg-flex"}>
          <Col xs={8} className={"mb-5"}>
            <Row>
              <Col xs={12} md={{ span: 6}}>
                <h1 className={""} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.section_2.title}}/>
                <p dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.section_2.desc}}/>
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: 6 }} className={"position-absolute text-center"}>
            <img src={data.parent_img.publicURL} alt={"App"} className={"parent-image w-100"}/>
          </Col>
          <Col xs={8}>
            <Row>
              <Col xs={12} md={{ span: 3}} className={"first-block"}>
                <p dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.section_2.blocks[0]}} />
              </Col>
              <Col xs={12} md={{ span: 3, offset: 4}} className={"mt-3 second-block"}>
                <p dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.section_2.blocks[1]}} />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={{ span: 3 }} className={"third-block"}>
                <p dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.section_2.blocks[2]}} />
              </Col>
              <Col xs={12} md={{ span: 3, offset: 5}} className={"mt-4 third-block n4"}>
                <p dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.section_2.blocks[3]}} />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={{ span: 5 }} className={"third-block"}>
                <p dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.section_2.blocks[4]}} />
              </Col>
              <Col xs={12} md={{ span: 2, offset: 5}} className={"mt-5 third-block n5"}>
                <p dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.section_2.blocks[5]}} />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className={"justify-content-center bg-light section-2 d-lg-none mt-3"}>
          <Col xs={10}>
            <h1 className={""} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.section_2.title}}/>
            <p dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.section_2.desc}}/>
          </Col>
          <Col xs={12} className={"text-center"}>
            <img src={data.parent_img.publicURL} alt={"App"} className={"parent-image w-100"}/>
          </Col>
        </Row>

        <Row className={"justify-content-center bg-white section-2 d-lg-none"}>
          <Col xs={10} className={""}>
            <ul>
              <li dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.section_2.blocks[0]}} />
              <li dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.section_2.blocks[1]}} />
              <li dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.section_2.blocks[2]}} />
              <li dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.section_2.blocks[3]}} />
              <li dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.section_2.blocks[4]}} />
              <li dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.section_2.blocks[5]}} />
            </ul>
          </Col>
        </Row>

        <Row className={"justify-content-center bg-white section-upsell"}>
          <Col xs={10} md={{ span: 8}} className={"my-5 sml"}>
            <h1 className={"mb-0"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.upsell_title}}/>
            <h2 className={"font-weight-normal"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.upsell_desc}}/>
          </Col>
        </Row>

        <Row className={"bg-dark darker section-5 justify-content-center py-5"}>
          <Col xs={10} md={{ span: 8}} className={"text-center"}>
            <Row className={"justify-content-center"}>
              <Col md={12} className={"text-center"}>
                <h1 className={"text-white"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.upcoming_features.title}} />
              </Col>
              { data.allDataJson.nodes[0].parents.upcoming_features.list.map((feat, i) => (
                <Col key={i} xs={{ span: 12 }} lg={{ span: 4 }} className={"p-1 d-flex align-items-start"}>
                  <Card className={"bg-white mx-2 text-center p-3 h-100"}>
                    <p className={"text-small mb-0 text-blue font-weight-bold"} dangerouslySetInnerHTML={{__html: feat}}/>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>

        <Row className={"bg-white section-3 pb-4"}>
          { data.allDataJson.nodes[0].parents.features.map((feat, i) => (
            <Col key={i} xs={{ span: 10, offset: 1 }} lg={{ span: 4, offset: (i % 2 === 0) ? 2 : 0 }} className={"p-3 d-flex align-items-start"}>
              <img src={feat_icon(i) ? data[feat_icon(i)].publicURL : data.i_circle.publicURL} alt={"Feature"} className={"parent-image mr-4"} style={{ maxWidth: "80px" }}/>
              <div>
                <h4 className={""} dangerouslySetInnerHTML={{__html: feat.title}}/>
                <p className={"text-small"} dangerouslySetInnerHTML={{__html: feat.desc}}/>
              </div>
            </Col>
          ))}
        </Row>

        <Row className={"justify-content-center bg-light section-4 py-5"}>
          <Col xs={10} md={{ span: 8}}>
            <Card>
              <Card.Header className={"border-0"}>
                <h3 className={"my-3"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.faq.title}}/>
              </Card.Header>
              <Card.Body className={"p-0 qas"}>
                <Accordion>
                  { data.allDataJson.nodes[0].parents.faq.qa.map((qa, key) => (
                    <div key={key+1} className={"border-bottom"}>
                      <Accordion.Toggle as={Card.Header} variant="link" eventKey={key+1} className={"my-2 border-0 font-weight-bold"} dangerouslySetInnerHTML={{__html: qa.q }} />
                      <Accordion.Collapse eventKey={key+1}>
                        <Card.Body className={"pt-0"} dangerouslySetInnerHTML={{__html: qa.a }} />
                      </Accordion.Collapse>
                    </div>
                  ))}
                </Accordion>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className={"bg-light section-5 justify-content-center text-danger pt-5 pb-4"}>
          <Col xs={10} md={{ span: 8}} className={"text-center"}>
            <h4 className={"mb-2"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].parents.support}} />
            <p><a href={"mailto:info@miopediatra.com"} className={"font-weight-bold text-danger"}>info@miopediatra.com</a></p>
          </Col>
        </Row>

      </div>
    </Layout>
  )
}

export default IndexPage
